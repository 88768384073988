import { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

const useCoverProduct = () => {
  const [coverProduct, setCoverProduct] = useState(null);

  const coverQuery = useStaticQuery(graphql`
    query coverQuery {
      allWpProduct(filter: { product: { sku: { eq: "window-well-cover" } } }) {
        edges {
          node {
            title
            content
            date(formatString: "MMMM DD, YYYY")
            slug
            featuredImage {
              node {
                title
                localFile {
                  publicURL
                }
              }
            }
            product {
              mainH1
              basePrice
              salePrice
              sku
              taxable
              category
              type
              cartCalloutText
              imageGallery {
                altText
                title
                localFile {
                  publicURL
                }
              }
              variations {
                variationName
                variationContent
                variationBasePrice
                variationSalePrice
                variationAttributes {
                  variationAttributeName
                  variationAttributeValue
                }
                variationSku
                variationSkus {
                  variationSkusSku
                }
                variationShortDescription
                variationDescription
                variationFeaturedImage {
                  title
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(
                        width: 800
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
                variationImageGallery {
                  altText
                  title
                  localFile {
                    publicURL
                  }
                }
              }
            }
            seo {
              title
              metaDesc
              canonical
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    setCoverProduct(coverQuery.allWpProduct.edges[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverQuery]);

  return coverProduct;
};
export default useCoverProduct;
