import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const HomeBlackSteel = () => (
  <StaticImage
    alt="Steel window well cover with house"
    src="../../images/black-steel-11-scaled-1.jpg"
    imgClassName="w-full rounded-md"
  />
);
export default HomeBlackSteel;
