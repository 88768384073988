import { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

const useLinerProduct = () => {
  const [linerProduct, setLinerProduct] = useState(null);

  const linerQuery = useStaticQuery(graphql`
    query LinerQuery {
      allWpProduct(filter: { product: { sku: { eq: "window-well-liner" } } }) {
        edges {
          node {
            title
            content
            date(formatString: "MMMM DD, YYYY")
            slug
            featuredImage {
              node {
                title
                localFile {
                  publicURL
                }
              }
            }
            product {
              mainH1
              basePrice
              salePrice
              sku
              taxable
              category
              type
              cartCalloutText
              imageGallery {
                altText
                title
                localFile {
                  publicURL
                }
              }
              variations {
                variationName
                variationBasePrice
                variationContent
                variationSalePrice
                variationAttributes {
                  variationAttributeName
                  variationAttributeValue
                }
                variationSku
                variationSkus {
                  variationSkusSku
                }
                variationShortDescription
                variationDescription
                variationFeaturedImage {
                  title
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(
                        width: 800
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
                variationImageGallery {
                  altText
                  title
                  localFile {
                    publicURL
                  }
                }
                outOfStock
                outOfStockShortDescription
                outOfStockLongDescription
              }
            }
            seo {
              title
              metaDesc
              canonical
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    setLinerProduct(linerQuery.allWpProduct.edges[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linerQuery]);

  return linerProduct;
};
export default useLinerProduct;
