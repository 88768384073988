import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const HomePolyCustom = () => (
  <StaticImage
    alt="Steel window well cover with house"
    src="../../images/poly-1-scaled.jpg"
    imgClassName="w-full rounded-md"
  />
);
export default HomePolyCustom;
