import classNames from "classnames";
import React, { useState } from "react";
import { BiX } from "react-icons/bi";
import Modal from "react-modal";
import CompareProductsTable from "./compare-products-table";

const componentStyles = {
  learnMoreButton: {
    Wasatch: `text-${process.env.THEME_COLOR_PRIMARY}-dark`,
    Mountainland: `text-${process.env.THEME_COLOR_PRIMARY}-darker`,
  },
};

function CompareProductsModal({
  learnMoreText = "Learn More",
  header,
  handleSelectOptionBySku,
  image = null,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectOption = (optionSku) => {
    handleSelectOptionBySku(optionSku);
    setIsOpen(false);
  };

  return (
    <>
      <button
        onClick={(event) => {
          event.stopPropagation();
          setIsOpen(true);
        }}
        className={classNames(
          "lg:ml-2 cursor-pointer underline hover:no-underline",
          componentStyles.learnMoreButton[process.env.COMPANY_NAME_SHORTER]
        )}
      >
        {learnMoreText}
      </button>

      <Modal
        isOpen={isOpen}
        contentLabel="Description Modal"
        className="bg-white shadow-lg p-6 mx-auto relative h-full max-h-[85vh] max-w-2xl"
        style={{
          overlay: {
            zIndex: "100",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            display: "flex",
            alignItems: "center",
            padding: "20px",
          },
        }}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setIsOpen(false);
        }}
      >
        <button
          aria-label="Close modal"
          className={classNames(
            "absolute p-2 rounded-full bg-grey-light right-2 top-2 z-20",
            "lg:top-[-50px] lg:right-2"
          )}
          onClick={(event) => {
            event.stopPropagation();
            setIsOpen(false);
          }}
        >
          <BiX size={25} />
        </button>
        <div className="h-full overflow-y-auto">
          <h2 className="text-lg mb-4 mr-8 lg:mr-0">{header}</h2>
          <div className="flex flex-wrap lg:flex-nowrap gap-4">
            {image && (
              <div>
                <img src={image} width={300} alt={image.altText || "Product"} />
              </div>
            )}
            <CompareProductsTable handleSelectOption={handleSelectOption} />
          </div>
        </div>
      </Modal>
    </>
  );
}
export default CompareProductsModal;
