import classNames from "classnames";
import { usePostHog } from "posthog-js/react";
import React, { useContext, useState } from "react";
import Star from "../components/partials/star";
import CartContext from "../context/cart-context";
import { Price } from "../utilities/price";
import { styles } from "../utilities/styles";

const componentStyles = {
  Mountainland: {
    price: `text-${process.env.THEME_COLOR_PRIMARY}-dark`,
  },
  Wasatch: {
    price: `text-${process.env.THEME_COLOR_PRIMARY}-darkest`,
  },
};

function SimpleProductTemplate(props) {
  const posthog = usePostHog();
  const cartContext = useContext(CartContext);
  const [quantity, setQuantity] = useState(1);
  if (
    !props.currentProduct.product.basePrice &&
    !props.currentProduct.product.salePrice
  ) {
    return (
      <div dangerouslySetInnerHTML={{ __html: props.currentProduct.content }} />
    );
  }

  function getSaleText() {
    if (!props.currentProduct.product.salePrice) return null;
    const percentOff =
      100 -
      (props.currentProduct.product.salePrice /
        props.currentProduct.product.basePrice) *
        100;
    return percentOff >= 35 ? (
      <span>More than 35% OFF</span>
    ) : (
      <span>{percentOff.toFixed(0)}% OFF</span>
    );
  }

  return (
    <div>
      <h1
        className="mt-0 mb-3 font-semibold text-lg lg:text-3xl text-black"
        dangerouslySetInnerHTML={{
          __html: props.currentProduct.title,
        }}
      />
      <div className="flex justify-start items-center w-full mb-4">
        <a
          href={process.env.GOOGLE_REVIEWS_PAGE}
          target="_blank"
          rel="noopener noreferrer nofollow"
          className={`text-${process.env.THEME_COLOR_PRIMARY}-dark no-underline flex items-center w-24`}
          title="5 Star Google Review"
        >
          <Star />
          <Star />
          <Star />
          <Star />
          <Star />
        </a>
        <a
          href={process.env.GOOGLE_REVIEWS_PAGE}
          className={`ml-2 pt-1 no-underline text-grey-darkest hover:text-${process.env.THEME_COLOR_PRIMARY}-darkest hover:underline`}
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          See reviews
        </a>
      </div>
      <p className="text-grey-dark">{process.env.TURNAROUND_PHRASE}</p>
      <div className="flex w-full gap-4 items-center mt-8 lg:mt-10 mb-4">
        <div
          className={classNames(
            `text-lg lg:text-2xl font-medium`,
            componentStyles[process.env.COMPANY_NAME_SHORTER].price
          )}
        >
          <Price
            value={
              props.currentProduct.product.salePrice ||
              props.currentProduct.product.basePrice
            }
          />
        </div>
        {props.currentProduct.product.salePrice && (
          <div className="line-through text-lg lg:text-2xl font-medium text-grey">
            <Price value={props.currentProduct.product.basePrice} />
          </div>
        )}
        {getSaleText() && (
          <div
            className={`font-medium uppercase bg-orange-lighter text-orange px-6 py-3 rounded-full`}
          >
            {getSaleText()}
          </div>
        )}
      </div>

      <div className="flex md:block">
        <label>
          Quantity:{" "}
          <select
            name="quantity"
            type="number"
            className="bg-white shadow appearance-none border border-grey-light rounded ml-2 mr-4 py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
            onBlur={(e) => {
              setQuantity(+e.currentTarget.value);
            }}
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="20">21</option>
            <option value="20">22</option>
            <option value="20">23</option>
            <option value="20">24</option>
            <option value="20">25</option>
            <option value="20">26</option>
            <option value="20">27</option>
            <option value="20">28</option>
            <option value="20">29</option>
            <option value="20">30</option>
          </select>
        </label>
        <button
          onClick={() => {
            if (typeof window !== `undefined` && window.gtag) {
              const productPrice = props.currentProduct.product.salePrice
                ? props.currentProduct.product.salePrice
                : props.currentProduct.product.basePrice;

              const eventData = {
                currency: "USD",
                value: productPrice * +quantity,
                items: [
                  {
                    item_name: props.currentProduct.title,
                    item_id: props.currentProduct.product.sku,
                    price: props.currentProduct.product.salePrice
                      ? props.currentProduct.product.salePrice
                      : props.currentProduct.product.basePrice,
                    item_brand: process.env.COMPANY_NAME,
                    item_category: Array.isArray(
                      props.currentProduct.product.category
                    )
                      ? props.currentProduct.product.category[0]
                      : props.currentProduct.product.category,
                    quantity: +quantity,
                  },
                ],
              };

              console.info("add_to_cart", eventData);

              try {
                window.gtag("event", "add_to_cart", eventData);
              } catch (error) {
                console.error("gtag error", error);
              }

              try {
                posthog.capture("add_to_cart", eventData);
              } catch (error) {
                console.error("posthog error", error);
              }
            }

            cartContext.addItemToCart({
              id: props.currentProduct.product.sku,
              skus: (props.currentProduct.product.variationSkus || []).map(
                (variationSku) => variationSku.variationSkusSku
              ),
              taxable: props.currentProduct.product.taxable,
              title: props.currentProduct.title,
              category: props.currentProduct.product.category,
              basePrice: +props.currentProduct.product.basePrice,
              salePrice: +props.currentProduct.product.salePrice,
              attributes: [],
              quantity: +quantity,
              url: `/${props.currentProduct.slug}/`,
              image:
                props.currentProduct.featuredImage.node.localFile.publicURL,
            });
            cartContext.setCartDrawerOpenStatus(true);
          }}
          className={classNames(
            "block md:inline-block text-sm px-4 py-2 mb-8 no-underline rounded uppercase",
            styles.button.default
          )}
        >
          Add To Cart
        </button>
        <h3>{props.currentProduct.name}</h3>
      </div>
      <hr className="border-b border-grey-light mb-8" />
      <div
        className="wp-content default-product"
        dangerouslySetInnerHTML={{ __html: props.currentProduct.content }}
      />
    </div>
  );
}
export default SimpleProductTemplate;
