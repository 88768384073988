import classNames from "classnames";
import React, { useContext, useMemo } from "react";
import { BiCheckCircle, BiMinusCircle } from "react-icons/bi";
import CartContext from "../../context/cart-context";
import { SupDollarSign } from "../../utilities/price";
import { styles } from "../../utilities/styles";
import HomeBlackSteel from "../images/home-black-steel";
import HomeBlackSteelTopper from "../images/home-black-steel-topper";
import HomePolyCustom from "../images/home-poly-custom";
import withLocation from "../shared/withLocation";

const CompareProducts = ({ handleSelectOption }) => {
  const cartContext = useContext(CartContext);
  const products = cartContext.homePageProducts();

  const steelCustom = useMemo(() => {
    if (!products) return null;
    const product = products.find(
      (product) => product.variationSku === "window-well-cover-steel-black"
    );

    return {
      name: product.variationName.replace(" Window Well Cover", ""),
      price: product?.variationSalePrice,
      shortDescription: product?.variationShortDescription,
    };
  }, [products]);

  const polyCustom = useMemo(() => {
    if (!products) return null;
    const product = products.find(
      (product) => product.variationSku === "window-well-cover-polycarbonate"
    );

    return {
      name: product.variationName.replace(" Window Well Cover", ""),
      price: product?.variationSalePrice,
      shortDescription: product?.variationShortDescription,
    };
  }, [products]);

  const topperCustom = useMemo(() => {
    if (!products) return null;
    const product = products.find(
      (product) =>
        product.variationSku === "window-well-cover-steel-black-poly-cover"
    );
    return {
      name: product.variationName.replace(" Window Well Cover", ""),
      price: product?.variationSalePrice,
      shortDescription: product?.variationShortDescription,
    };
  }, [products]);

  return (
    <table
      className="bpd-compare-products-table"
      cellPadding={10}
      cellSpacing={10}
    >
      <thead>
        <tr className="sticky top-0 z-10 bg-white">
          <th>
            <p className="sr-only">Category</p>
          </th>
          <th className="w-[29%] align-top">
            <h2 className="text-sm leading-tight md:text-base">Custom Steel</h2>
          </th>
          <th className="w-[29%] align-top">
            <h2 className="text-sm leading-tight md:text-base">
              Custom Steel with Polycarbonate Topper
            </h2>
          </th>
          <th className="w-[29%] align-top">
            <h2 className="text-sm leading-tight md:text-base">
              Custom Polycarbonate
            </h2>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className="hidden md:table-row">
          <td>
            <p className="sr-only">Image</p>
          </td>
          <td aria-label="Steel cover image">
            <HomeBlackSteel />
          </td>
          <td aria-label="Steel with polycarbonate topper cover image">
            <HomeBlackSteelTopper smallBestValue />
          </td>
          <td aria-label="Polycarbonate cover image">
            <HomePolyCustom />
          </td>
        </tr>
        <tr className="hidden md:table-row">
          <td>
            <p className="sr-only">Description</p>
          </td>
          <td
            aria-label="Steel cover description"
            className="text-grey-darker align-top"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: steelCustom ? steelCustom.shortDescription : "",
              }}
            />
          </td>
          <td
            aria-label="Steel with poly topper description"
            className="text-grey-darker align-top"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: topperCustom ? topperCustom.shortDescription : "",
              }}
            />
          </td>
          <td
            aria-label="Poly cover description"
            className="text-grey-darker align-top"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: polyCustom ? polyCustom.shortDescription : "",
              }}
            />
          </td>
        </tr>
        <tr className="hover:bg-grey-lightest">
          <td className="border-t border-grey-light leading-tight text-sm md:text-base">
            <strong>Polycarbonate topper</strong>
          </td>
          <NoCell />
          <YesCell />
          <YesCell />
        </tr>
        <tr className="hover:bg-grey-lightest">
          <td className="border-t border-grey-light leading-tight text-sm md:text-base">
            <strong>Removable polycarbonate topper</strong>
          </td>
          <NoCell />
          <YesCell />
          <NoCell />
        </tr>
        <tr className="hover:bg-grey-lightest">
          <td className="border-t border-grey-light leading-tight text-sm md:text-base">
            <strong>Premium Grade Steel</strong>
          </td>
          <YesCell />
          <YesCell />
          <NoCell />
        </tr>
        <tr className="hover:bg-grey-lightest">
          <td className="border-t border-grey-light leading-tight text-sm md:text-base">
            <strong>Military Grade Aluminum</strong>
          </td>
          <NoCell />
          <NoCell />
          <YesCell />
        </tr>
        <tr className="hover:bg-grey-lightest">
          <td className="border-t border-grey-light leading-tight text-sm md:text-base">
            <strong>Helps Keep Water Out</strong>
          </td>
          <NoCell />
          <YesCell />
          <YesCell />
        </tr>
        <tr className="hover:bg-grey-lightest">
          <td className="border-t border-grey-light leading-tight text-sm md:text-base">
            <strong>Available with a Black or Brown Finish</strong>
          </td>
          <YesCell />
          <YesCell />
          <NoCell explanation="Aluminum only" />
        </tr>
        <tr className="hover:bg-grey-lightest">
          <td className="border-t border-grey-light leading-tight text-sm md:text-base">
            <strong>Extends Window Well Liner's Warranty by 5 Years</strong>
          </td>
          <NoCell />
          <YesCell />
          <YesCell />
        </tr>
        <tr className="hover:bg-grey-lightest">
          <td className="border-t border-grey-light leading-tight text-sm md:text-base">
            <strong>Anti-Corrosive</strong>
          </td>
          <YesCell />
          <YesCell />
          <YesCell />
        </tr>
        <tr className="hover:bg-grey-lightest">
          <td className="border-t border-grey-light leading-tight text-sm md:text-base">
            <strong>Holds more than 400 lbs</strong>
          </td>
          <YesCell />
          <YesCell />
          <YesCell />
        </tr>
        <tr className="hover:bg-grey-lightest">
          <td className="border-t border-grey-light leading-tight text-sm md:text-base">
            <strong>Lifetime Structural Warranty</strong>
          </td>
          <YesCell />
          <YesCell />
          <YesCell />
        </tr>
        <tr className="hover:bg-grey-lightest">
          <td className="border-t border-grey-light">
            <strong>Starting At</strong>
          </td>
          <td className="border-t border-grey-light text-center text-lg">
            {steelCustom ? (
              <>
                <SupDollarSign />
                {steelCustom.price}
              </>
            ) : (
              "N/A"
            )}
          </td>
          <td className="border-t border-grey-light text-center text-lg">
            {topperCustom ? (
              <>
                <SupDollarSign />
                {topperCustom.price}
              </>
            ) : (
              "N/A"
            )}
          </td>
          <td className="border-t border-grey-light text-center text-lg">
            {polyCustom ? (
              <>
                <SupDollarSign />
                {polyCustom.price}
              </>
            ) : (
              "N/A"
            )}
          </td>
        </tr>
        <tr className="hover:bg-grey-lightest">
          <td>
            <p className="sr-only">Select</p>
          </td>
          <td>
            <button
              className={classNames(
                "w-full p-2 text-white rounded",
                styles.button.default
              )}
              onClick={() => {
                handleSelectOption("window-well-cover-steel-black");
              }}
            >
              Select
            </button>
          </td>
          <td>
            <button
              className={classNames(
                "w-full p-2 text-white rounded",
                styles.button.default
              )}
              onClick={() => {
                handleSelectOption("window-well-cover-steel-black-poly-cover");
              }}
            >
              Select
            </button>
          </td>
          <td>
            <button
              className={classNames(
                "w-full p-2 text-white rounded",
                styles.button.default
              )}
              onClick={() =>
                handleSelectOption("window-well-cover-polycarbonate")
              }
            >
              Select
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const YesCell = () => {
  return (
    <td
      aria-label="Checked"
      className="border-t border-grey-light align-middle"
    >
      <BiCheckCircle
        className={`text-${process.env.THEME_COLOR_PRIMARY}-dark w-6 h-6 mx-auto`}
      />
    </td>
  );
};

const NoCell = ({ explanation }) => {
  return (
    <td
      aria-label="Unchecked"
      className="border-t border-grey-light align-middle"
    >
      <BiMinusCircle className="text-grey-light w-6 h-6 mx-auto" />
      {explanation && (
        <p className="text-grey-dark text-center text-sm tracking-wide pt-1">
          {explanation}
        </p>
      )}
    </td>
  );
};

export default withLocation(CompareProducts);
