import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { styles } from "../../utilities/styles";

const HomeBlackSteelTopper = ({ smallBestValue = false }) => (
  <div className="relative w-full rounded-sm">
    <StaticImage
      alt="Steel window well cover with house"
      src="../../images/steel-poly-11-scaled-1.jpg"
      imgClassName="w-full rounded-md"
    />
    <span
      className={classNames(
        `rounded-full absolute p-4 font-bold shadow-lg text-center`,
        styles.button.default,
        smallBestValue
          ? "text-xs -top-2 -left-3"
          : "text-sm lg:p-5 -top-6 -left-10"
      )}
    >
      Best <br />
      Value!
    </span>
  </div>
);
export default HomeBlackSteelTopper;
